import React from "react";
import "./EmptyProducts.scss";

const EmptyProduct = () => {
  return (
    <div className="empty-product">
      <span>No Products Found !!</span>
    </div>
  );
};

export default EmptyProduct;
