import { motion } from "framer-motion";
import "./DropdownList.scss";
import { useDispatch, useSelector } from "react-redux";
import {
  GetProductsAction,
  GetProductsByTypeAction,
} from "../../../redux/actions/ProductAction";
import { clearProducts } from "../../../redux/reducers/ProductReducer";
import { useCallback } from "react";
import { paginatorSize } from "../../../redux/constant";
import { useNavigate } from "react-router-dom";
import { setActiveTab, setOpen } from "../../../redux/utils/sidebarSlice";

const variants = {
  open: {
    transition: {
      staggerChildren: 0.1,
    },
  },
  closed: {
    transition: {
      staggerChildren: 0.05,
      staggerDirection: -1,
    },
  },
};
const itemVariants = {
  open: {
    y: 0,
    opacity: 1,
  },
  closed: {
    y: 50,
    opacity: 0,
  },
};

const DropdownList = () => {
  const items = [
    { name: "كل الأصناف", type: -1 },
    { name: "عناية بالشعر", type: 1 },
    { name: "عناية بالبشرة", type: 2 },
    { name: "عناية بالأظافر والرموش والحواجب", type: 3 },
    { name: "كورسات علاجية", type: 4 },
    { name: "العروض", type: 5 },
  ];
  const dispatch = useDispatch();
  const { currentPage } = useSelector((state) => state.products);
  const navigate = useNavigate();

  const sectionHandling = useCallback(
    ({ type }) => {
      dispatch(setActiveTab(type));
      if (type === -1) {
        let info = { size: paginatorSize, page: currentPage, isPaginate: 1 };
        dispatch(GetProductsAction(info)).then(({ payload }) => {
          if (payload?.status) {
            navigate("/");
            setTimeout(() => {
              window.location.href = "#products";
            }, 500);
            dispatch(setOpen(false));
          }
        });
      }
      if (type !== -1) {
        let info = {
          isPaginate: 0,
          type: type,
        };
        dispatch(GetProductsByTypeAction(info))
          .then(({ payload }) => {
            if (payload?.status) {
              navigate("/");
              setTimeout(() => {
                window.location.href = "#products";
              }, 500);
              dispatch(setOpen(false));
            }
          })
          .catch((error) => {
            console.error("Error:", error);
          });
        dispatch(clearProducts());
      }
    },
    [dispatch, currentPage, navigate]
  );

  // const sectionHandling = ({ type }) => {
  //   dispatch(setActiveTab(type));
  //   if (type === -1) {
  //     let info = { size: paginatorSize, page: currentPage, isPaginate: 1 };
  //     dispatch(GetProductsAction(info)).then(({ payload }) => {
  //       console.log(payload);
  //       if (payload?.status) {
  //         navigate("/");
  //         setTimeout(() => {
  //           window.location.href = "#products";
  //         }, 500);
  //         dispatch(setOpen(false));
  //       }
  //     });
  //   }
  //   if (type !== -1) {
  //     let info = {
  //       isPaginate: 0,
  //       type: type,
  //     };
  //     dispatch(GetProductsByTypeAction(info))
  //       .then(({ payload }) => {
  //         if (payload?.status) {
  //           console.log(payload);
  //           navigate("/");
  //           setTimeout(() => {
  //             window.location.href = "#products";
  //           }, 500);
  //           dispatch(setOpen(false));
  //         }
  //       })
  //       .catch((error) => {
  //         console.error("Error:", error);
  //       });
  //     dispatch(clearProducts());
  //   }
  // };

  return (
    <motion.div className="dropdown-list" variants={variants}>
      {items.map((item, index) => (
        <motion.div
          className="dropdown-item"
          key={index}
          variants={itemVariants}
          whileTap={{ scale: 0.95 }}
          onClick={() => sectionHandling(item)}
        >
          <span>{item.name}</span>
        </motion.div>
      ))}
    </motion.div>
  );
};

export default DropdownList;
