import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { imageURL } from "../../redux/Api";
import {
  OrderProductAction,
  ShowProductAction,
} from "../../redux/actions/ProductAction";
import { toast } from "react-toastify";
import { useDispatch, useSelector } from "react-redux";
import LoadingFS from "../../components/loading/LoadingFS";
import "./Product.scss";
import { getLanguageClassName } from "../../utils/utils";

const Product = () => {
  const dispatch = useDispatch();
  const { loading, productInfo } = useSelector((state) => state.products);
  const [email, setEmail] = useState("");
  const [quantity, setQuantity] = useState("");
  const [description, setDescription] = useState("");
  const { id } = useParams();

  useEffect(() => {
    dispatch(ShowProductAction(id));
  }, [dispatch, id]);

  const orderProductHandler = (e) => {
    e.preventDefault();
    let obj = new FormData();
    obj.append("email", email);
    obj.append("description", description);
    obj.append("quantity", quantity);
    let info = {
      id: id,
      obj: obj,
    };
    dispatch(OrderProductAction(info))
      .then(({ payload }) => {
        if (payload?.status) {
          toast.success(payload.message);
          setEmail("");
          setDescription("");
          setQuantity("");
        } else {
          toast.error(payload);
        }
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  };

  return (
    <div className="product">
      {loading && <LoadingFS />}
      <div className="product-image">
        <img
          src={
            productInfo.image_path
              ? imageURL + productInfo.image_path
              : require("../../assets/images/default.png")
          }
          alt="cosmetics"
        />
      </div>
      <div className="content">
        <div className="information">
          <div className="header">طلب المنتج</div>
          <div className="title">{productInfo.title}</div>
          <div className="price">
            {productInfo.price ? productInfo.price + " SYP" : "Not Assigned"}
          </div>
          <div
            className={`desc ${getLanguageClassName(productInfo.description)}`}
          >
            {productInfo.description}
          </div>
        </div>

        <form className="submit" onSubmit={orderProductHandler}>
          <div>
            <label htmlFor="email">Email</label>
            <input
              value={email}
              type="email"
              name="email"
              onChange={(e) => {
                setEmail(e.target.value);
              }}
            />
          </div>

          <div>
            <label htmlFor="quantity">Quantity</label>
            <input
              value={quantity}
              type="number"
              name="quantity"
              onChange={(e) => {
                setQuantity(e.target.value);
              }}
            />
          </div>
          <div>
            <label htmlFor="description">Description</label>
            <textarea
              value={description}
              className={`${getLanguageClassName(description)}`}
              rows={4}
              type="text"
              name="description"
              onChange={(e) => {
                setDescription(e.target.value);
              }}
            />
          </div>
          <div className="product-button">
            <button type="submit">طلب</button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default Product;
