import { configureStore } from '@reduxjs/toolkit';
import AboutUsReducer from './reducers/AboutUsReducer';
import ProductReducer from './reducers/ProductReducer';
import sidebarSlice from './utils/sidebarSlice';

const store = configureStore({
    reducer: {
        products: ProductReducer,
        about: AboutUsReducer,
        sideUtil: sidebarSlice
    }
});
export default store