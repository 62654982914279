import { createSlice } from '@reduxjs/toolkit';
import { GetAboutUsAction } from '../actions/AboutUsAction';
export const aboutUsReducer = createSlice({
    name: "About Reducer",
    initialState: {
        loading: false,
        aboutUs: [],
        keyWords: []
    },
    reducers: {
    },
    extraReducers: (builder) => {
        builder
            .addCase(GetAboutUsAction.pending, (state) => {
                state.loading = true;
            })
            .addCase(GetAboutUsAction.fulfilled, (state, { payload }) => {
                // console.log(payload)
                state.loading = false;
                state.aboutUs = payload.data;
                state.keyWords = [
                    payload.data.word_one,
                    payload.data.word_two,
                    payload.data.word_three,
                    payload.data.word_four,
                    payload.data.word_five
                ]
            })
            .addCase(GetAboutUsAction.rejected, (state, { payload }) => {
                // console.log(payload)
                state.loading = false;
            })

    }
});
export default aboutUsReducer.reducer;
