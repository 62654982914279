import React, { useEffect } from "react";
import { motion } from "framer-motion";
import { useDispatch, useSelector } from "react-redux";
import { GetAboutUsAction } from "../../redux/actions/AboutUsAction";
import { imageURL } from "../../redux/Api";
import { ReactTyped } from "react-typed";
import LoadingFS from "../../components/loading/LoadingFS";
import { clearProducts } from "../../redux/reducers/ProductReducer";
import { paginatorSize } from "../../redux/constant";
import { GetProductsAction } from "../../redux/actions/ProductAction";
import "./Home.scss";

const Home = () => {
  const dispatch = useDispatch();
  const { activeTab } = useSelector((state) => state.sideUtil);
  const { loading, aboutUs, keyWords } = useSelector((state) => state.about);

  useEffect(() => {
    dispatch(GetAboutUsAction());
    if (activeTab === -1) {
      dispatch(clearProducts());
      let info = { size: paginatorSize, page: 1, isPaginate: 1 };
      dispatch(GetProductsAction(info));
    }
  }, [dispatch, activeTab]);

  return (
    <>
      {loading && <LoadingFS />}
      <div className="home">
        <div className="cover-image">
          <motion.img
            initial={{
              x: -100,
            }}
            animate={{
              x: 0,
            }}
            transition={{ duration: 1 }}
            src={imageURL + aboutUs.image_path}
            alt=""
          />
        </div>
        <div className="content">
          <div className="name">I'm Dr.Haya Ghassa</div>
          <div>
            <span style={{ visibility: "hidden" }}>.</span>
            {aboutUs.length !== 0 && (
              <ReactTyped
                stopped={true}
                strings={keyWords}
                loop
                typeSpeed={100}
                backSpeed={30}
                smartBackspace
                startWhenVisible
              />
            )}
          </div>
          <div className="bio">
            {aboutUs.length !== 0 && (
              <ReactTyped
                strings={aboutUs.bio ? [aboutUs.bio] : []} // Check if aboutUs.bio is defined
                typeSpeed={20}
                startWhenVisible
                showCursor={false}
                stopped={true}
              />
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default Home;
