import React from "react";
import { Link } from "react-router-dom";
import { imageURL } from "../../redux/Api";
import "./ProductCard.scss";

const ProductCard = ({ id, title, img, price, desc }) => {
  return (
    <div className="product-card">
      <img
        src={img ? imageURL + img : require("../../assets/images/default.png")}
        alt={title}
        className="product-img"
      />
      <div className="product-details">
        <h2 className="product-title">{title}</h2>
        <div className="product-price">
          {price ? price + " SYP" : "Not Assigned"}
        </div>
        <Link to={`products/${id}`}>
          <div className="product-button">التفاصيل</div>
        </Link>
      </div>
    </div>
  );
};

export default ProductCard;
