// utils.js
import { useEffect, useState } from "react";

export const useScrollHandler = (threshold) => {
    const [scrolled, setScrolled] = useState(false);

    useEffect(() => {
        const handleScroll = () => {
            if (window.scrollY > window.innerHeight * threshold) {
                setScrolled(true);
            } else {
                setScrolled(false);
            }
        };

        window.addEventListener("scroll", handleScroll);

        return () => {
            window.removeEventListener("scroll", handleScroll);
        };
    }, [threshold]);

    return scrolled;
};

export const getLanguageClassName = (inputText) => {
    const arabicRegex = /[\u0600-\u06FF]/;

    if (arabicRegex.test(inputText)) {
        return "ar"; 
    } else {
        return "en";
    }
}
