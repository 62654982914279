import React, { useRef, useState } from "react";
import { motion, useInView } from "framer-motion"; // Import motion from framer-motion
import ProductCard from "../../components/product-card/ProductCard";
import { GetProductsAction } from "../../redux/actions/ProductAction";
import { useDispatch, useSelector } from "react-redux";
import LoadingFS from "../../components/loading/LoadingFS";
import EmptyProduct from "../../components/empty-products/EmptyProduct";
import { paginatorSize } from "../../redux/constant";
import "./Products.scss";

const container = {
  hidden: { opacity: 0, scale: 0 },
  visible: {
    opacity: 1,
    scale: 1,
    transition: {
      delayChildren: 0.3,
      staggerChildren: 0.2,
    },
  },
};

const item = {
  hidden: { y: 20, opacity: 0 },
  visible: {
    y: 0,
    opacity: 1,
    scale: 1,
  },
};

const Products = () => {
  const ref = useRef(null);
  const dispatch = useDispatch();
  const isInView = useInView(ref);
  const [counter, setCounter] = useState(1);
  const { loading, products, totalItems, withPaginate, currentPage } =
    useSelector((state) => state.products);

  const handleLoadMore = () => {
    setCounter(counter + 1);
    let info = {
      size: paginatorSize,
      page: currentPage + 1,
      isPaginate: 1,
    };
    dispatch(GetProductsAction(info));
  };
  return (
    <div className="products" id="products" ref={ref}>
      {loading && <LoadingFS />}
      {products?.length !== 0 ? (
        <div>
          <motion.div
            className="grid-container"
            variants={container}
            initial="hidden"
            animate={isInView ? "visible" : "hidden"}
          >
            {products?.map((product) => (
              <motion.div key={product.id} variants={item}>
                <ProductCard
                  id={product.id}
                  desc={product.description}
                  title={product.title}
                  img={product.image_path}
                  price={product.price}
                />
              </motion.div>
            ))}
          </motion.div>
          {withPaginate && totalItems !== products?.length && (
            <div className="load-btn">
              <button onClick={handleLoadMore}>عرض المزيد</button>
            </div>
          )}
        </div>
      ) : (
        <EmptyProduct />
      )}
    </div>
  );
};

export default Products;
