import { motion } from "framer-motion";
import Sidebar from "../sidebar/SideBar";
import { useState } from "react";
import { getLanguageClassName, useScrollHandler } from "../../utils/utils";
import { toast } from "react-toastify";
import { SearchProductsAction } from "../../redux/actions/ProductAction";
import { useDispatch } from "react-redux";
import "./Navbar.scss";
import { useNavigate } from "react-router-dom";
import { clearCurrentPage } from "../../redux/reducers/ProductReducer";

const Navbar = () => {
  const navigate = useNavigate();
  const [open, setOpen] = useState(false);
  const [searchValue, setSearchValue] = useState("");
  const dispatch = useDispatch();
  let scrolled = useScrollHandler(0.1);

  const searchHandler = (event) => {
    if (event.key === "Enter") {
      if (event.target.value.length < 3) {
        toast.warning(
          "Please enter at least three characters to start searching."
        );
        return;
      }
      let info = {
        title: searchValue,
        isPaginate: 0,
      };
      dispatch(SearchProductsAction(info)).then(({ payload }) => {
        if (payload?.data?.length !== 0) {
          navigate("/");
          setTimeout(() => {
            window.location.href = "#products";
          }, 500);
        } else {
          toast.error("No Products Found");
        }
        setSearchValue("");
        dispatch(clearCurrentPage());
      });
    }
  };
  return (
    <div className={`navbar ${scrolled ? "scrolled" : ""}`}>
      <Sidebar />
      {!open && (
        <motion.div
          className="search-icon"
          onClick={() => {
            setOpen(true);
          }}
        >
          <i className="fas fa-search"></i>
        </motion.div>
      )}
      {open && (
        <motion.div
          initial={{ opacity: 0, scale: 0.5 }}
          animate={{ opacity: 1, x: 0, scale: 1 }}
          transition={{ duration: 0.3 }}
          className="search-bar"
        >
          <input
            value={searchValue}
            onChange={(e) => setSearchValue(e.target.value)}
            type="text"
            onKeyDown={searchHandler}
            placeholder="Search..."
            className={`${getLanguageClassName(searchValue)}`}
          />
        </motion.div>
      )}
      <motion.span
        className="logo"
        initial={{ opacity: 0, scale: 0.5 }}
        animate={{ opacity: 1, scale: 1 }}
        transition={{ duration: 1 }}
      >
        <img src="/logo.png" alt="Logo" />
      </motion.span>
    </div>
  );
};

export default Navbar;
