import Home from "./pages/home/Home";
import Products from "./pages/products/Products";
import Navbar from "./components/navbar/Navbar";
import Footer from "./components/footer/Footer";
import { BrowserRouter, Route, Routes } from 'react-router-dom'
import Product from "./pages/product/Product";
import { ToastContainer } from 'react-toastify';

function App() {
  return (
    <BrowserRouter basename="/">
      <div className="body-container">
        <Navbar />
        <Home />
        <Routes >
          <Route path="/products/:id" element={<Product />} />
          <Route path="/" element={<Products />} />
        </Routes>
        <Footer />
        <ToastContainer draggable position='bottom-right' />
      </div>
    </BrowserRouter>
  );
}

export default App;
