import { createSlice } from '@reduxjs/toolkit';
import { GetProductsAction, GetProductsByTypeAction, OrderProductAction, SearchProductsAction, ShowProductAction } from '../actions/ProductAction';
export const productReducer = createSlice({
    name: "Product Reducer",
    initialState: {
        loading: false,
        totalItems: "",
        currentPage: 0,
        products: [],
        productInfo: [],
        withPaginate: false
    },
    reducers: {
        clearProducts(state) {
            state.products = [];
        },
        clearCurrentPage(state) {
            state.currentPage = 0;
        }
    },
    extraReducers: (builder) => {
        builder
            .addCase(OrderProductAction.pending, (state) => {
                state.loading = true;
            })
            .addCase(OrderProductAction.fulfilled, (state, { payload }) => {
                // console.log(payload)
                state.loading = false;
            })
            .addCase(OrderProductAction.rejected, (state, { payload }) => {
                // console.log(payload)
                state.loading = false;
            })

            .addCase(GetProductsAction.pending, (state) => {
                state.loading = true;
            })
            .addCase(GetProductsAction.fulfilled, (state, { payload }) => {
                // console.log(payload)
                state.loading = false;
                state.products = [
                    ...state.products,
                    ...payload.data.data.filter((newProduct) => {
                        return !state.products.some((product) => product.id === newProduct.id);
                    })
                ];
                state.totalItems = payload.data.totalItems;
                state.currentPage = payload.data.currentPage;
                state.withPaginate = true
            })
            .addCase(GetProductsAction.rejected, (state, { payload }) => {
                // console.log(payload)
                state.loading = false;
            })

            .addCase(GetProductsByTypeAction.pending, (state) => {
                state.loading = true;
            })
            .addCase(GetProductsByTypeAction.fulfilled, (state, { payload }) => {
                // console.log(payload)
                state.loading = false;
                state.products = payload.data;
                state.withPaginate = false
            })
            .addCase(GetProductsByTypeAction.rejected, (state, { payload }) => {
                // console.log(payload)
                state.loading = false;
            })

            .addCase(SearchProductsAction.pending, (state) => {
                state.loading = true;
            })
            .addCase(SearchProductsAction.fulfilled, (state, { payload }) => {
                // console.log(payload)
                state.loading = false;
                state.products = payload.data;
                state.withPaginate = false
            })
            .addCase(SearchProductsAction.rejected, (state, { payload }) => {
                // console.log(payload)
                state.loading = false;
            })

            .addCase(ShowProductAction.pending, (state) => {
                state.loading = true;
            })
            .addCase(ShowProductAction.fulfilled, (state, { payload }) => {
                console.log(payload)
                state.loading = false;
                state.productInfo = payload.data
            })
            .addCase(ShowProductAction.rejected, (state, { payload }) => {
                // console.log(payload)
                state.loading = false;
            })
    }
});
export default productReducer.reducer;
export const { clearProducts, clearCurrentPage } = productReducer.actions;
