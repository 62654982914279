import { createSlice } from '@reduxjs/toolkit';

export const sidebarSlice = createSlice({
    name: 'sidebar',
    initialState: {
        open: false,
        activeTab: -1
    },
    reducers: {
        setOpen: (state, action) => {
            state.open = action.payload;
        },
        setActiveTab: (state, action) => {
            state.activeTab = action.payload;
        },
    },
});

export const { setOpen, setActiveTab } = sidebarSlice.actions;
export default sidebarSlice.reducer;
