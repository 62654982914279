import React from "react";
import { motion } from "framer-motion";
import "./SideBar.scss";
import DropdownList from "./links/DropdownList";
import ToggleButton from "./toggle-button/ToogleButton";
import { useDispatch, useSelector } from "react-redux";
import { setOpen } from "../../redux/utils/sidebarSlice";

const variants = {
  open: {
    opacity: 1,
    transition: {
      type: "tween",
      duration: 0.5,
    },
  },
  closed: {
    opacity: 0,
    transition: {
      type: "tween",
      duration: 0.5,
    },
  },
};

const SideBar = () => {
  const { open } = useSelector((state) => state.sideUtil);
  const dispatch = useDispatch();

  const toggleSidebar = () => {
    dispatch(setOpen(!open));
  };

  return (
    <motion.div
      className={`sidebar ${!open && "hide"}`}
      animate={open ? "open" : "closed"}
    >
      <motion.div className="bg" variants={variants}>
        <DropdownList />
      </motion.div>
      <ToggleButton setOpen={toggleSidebar} />
    </motion.div>
  );
};

export default SideBar;
