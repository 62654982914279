import React from 'react'
import './loadingFS.scss'
const LoadingFS = () => {
    return (
        <div className='center'>
            <div className="lds-hourglass"></div>
        </div>
    )
}

export default LoadingFS