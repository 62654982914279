import { createAsyncThunk } from "@reduxjs/toolkit";
import { errorMessage } from "../constant";
import { GetAboutUsAPI } from "../Api";
import axios from "axios";

export const GetAboutUsAction = createAsyncThunk("user/about-us/get", async (info, { rejectWithValue }) => {
    try {
        let { data } = await axios.get(GetAboutUsAPI);
        return data;
    } catch (error) {
        if (error.response) {
            const validationMessage = error.response.data;
            return rejectWithValue(validationMessage);
        } else {
            return rejectWithValue(errorMessage);
        }
    }
});
