import { createAsyncThunk } from "@reduxjs/toolkit";
import { GetProductsAPI, GetProductsByTypeAPI, OrderProductAPI, SearchProductsAPI, ShowProductsAPI, } from "../Api";
import axios from "axios";
import { errorMessage } from "../constant";

export const GetProductsAction = createAsyncThunk("admin/products/get", async (info, { rejectWithValue }) => {
    try {
        let { data } = await axios.get(GetProductsAPI + info.page + "&size=" + info.size + "&isPaginate=" + info.isPaginate);
        return data;
    } catch (error) {
        if (error.response) {
            const validationMessage = error.response.data.message;
            return rejectWithValue(validationMessage);
        } else {
            return rejectWithValue(errorMessage);
        }
    }
});

export const GetProductsByTypeAction = createAsyncThunk("admin/products/type/get", async (info, { rejectWithValue }) => {
    try {
        let { data } = await axios.get(GetProductsByTypeAPI + info.type + "?page=" + info.page + "&size=" + info.size + "&isPaginate=" + info.isPaginate);
        return data;
    } catch (error) {
        if (error.response) {
            const validationMessage = error.response.data.message;
            return rejectWithValue(validationMessage);
        } else {
            return rejectWithValue(errorMessage);
        }
    }
});

export const SearchProductsAction = createAsyncThunk("admin/products/search/get", async (info, { rejectWithValue }) => {
    try {
        let { data } = await axios.get(SearchProductsAPI + info.page +
            "&size=" + info.size +
            "&isPaginate=" + info.isPaginate +
            "&title=" + info.title);
        return data;
    } catch (error) {
        if (error.response) {
            const validationMessage = error.response.data.message;
            return rejectWithValue(validationMessage);
        } else {
            return rejectWithValue(errorMessage);
        }
    }
});

export const OrderProductAction = createAsyncThunk("admin/products/order", async (info, { rejectWithValue }) => {
    try {
        let { data } = await axios.post(OrderProductAPI + info.id, info.obj);
        return data;
    } catch (error) {
        if (error.response) {
            const validationMessage = error.response.data.message;
            return rejectWithValue(validationMessage);
        } else {
            return rejectWithValue(errorMessage);
        }
    }
});

export const ShowProductAction = createAsyncThunk("admin/product/show", async (info, { rejectWithValue }) => {
    try {
        let { data } = await axios.get(ShowProductsAPI + info);
        return data;
    } catch (error) {
        if (error.response) {
            const validationMessage = error.response.data.message;
            return rejectWithValue(validationMessage);
        } else {
            return rejectWithValue(errorMessage);
        }
    }
});