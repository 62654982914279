import React from "react";
import "./Footer.scss";
import { useSelector } from "react-redux";

const Footer = () => {
  const { aboutUs } = useSelector((state) => state.about);
  return (
    <footer className="footer">
      <div className="logo">
        <img src="/logo.png" alt="Logo" />
      </div>
      <div className="social-icons">
        <a href={aboutUs.facebook} target="_blank" rel="noopener noreferrer">
          <img
            src={`${require("../../assets/images/social-icons/facebook.png")}`}
            alt="facebook-haya-ghassa"
          />
        </a>
        <a href={`mailto:${aboutUs.email}`} className="social-icon">
          <img
            src={require("../../assets/images/social-icons/mail.png")}
            alt="mail-icon"
          />
        </a>
        <a href={aboutUs.instagram} target="_blank" rel="noopener noreferrer">
          <img
            src={`${require("../../assets/images/social-icons/instagram.png")}`}
            alt=""
          />
        </a>
      </div>
      <div className="copywrite-text">
        <div>{aboutUs.phone_mobile}</div>
        &copy; 2024 Mr.Apps All rights reserved.
      </div>
    </footer>
  );
};

export default Footer;
