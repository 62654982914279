export const baseURL = 'https://cosmetics.it.mr-appss.com/api/';
export const imageURL = 'https://cosmetics.it.mr-appss.com/';

//Login
export const loginAPI = `${baseURL}auth/login`

//Products
export const GetProductsAPI = `${baseURL}client/product/get?page=`
export const GetProductsByTypeAPI = `${baseURL}client/product/get/`
export const SearchProductsAPI = `${baseURL}client/product/search?page=`
export const OrderProductAPI = `${baseURL}client/order/product/`
export const ShowProductsAPI = `${baseURL}client/product/show/`

//About
export const GetAboutUsAPI = `${baseURL}client/about/get`